@import 'styles/vars';

.container {
  position: relative;
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: $head-size;
  transition: background-color $ease-in;

  &.black {
    background-color: $dark;
    color: $white;
  }
  &.white {
    background-color: $white;
  }
  &.light {
    background-color: $light !important;
  }

  &.text-color-white * {
    color: $white;
    fill: $white;
  }

  @media (max-width: $w992) {
    height: $head-size-mobile;
  }

  &.active {
    background-color: $darkest !important;

    .wrapper {
      @media (max-width: $w992) {
        border-bottom: none;
      }
    }
  }
}

.fixedWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  transition: transform 0.2s ease-out;

  &.active {
    z-index: 1;
  }

  &.fixed {
    position: fixed;
    top: -6.25rem;
    border-bottom: 1px solid $headerBorder;

    @media (max-width: $w992) {
      border-bottom: none;
    }

    .fixedTransparent {
      background: $white;
    }

    @media (max-width: $w992) {
      top: -4.375rem;

      .black {
        background: linear-gradient(
          180deg,
          $dark 50%,
          rgba(45, 45, 45, 0) 100%
        );
      }

      .white {
        background: linear-gradient(
          180deg,
          $white 50%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }
  }
  &:global(.fixed-enter-active) {
    transform: translateY(0);
  }
  &:global(.fixed-enter-done) {
    transform: translateY(calc(100% - 1px));
  }
  &:global(.fixed-exit-active) {
    transform: translateY(0);
    position: fixed;
    top: -6.25rem;

    @media (max-width: $w992) {
      top: -4.375rem;
    }
  }
}

.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.hideBorder {
  @media (max-width: $w992) {
    border-bottom: none;
  }
}

.mobileNavWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  padding-bottom: 4.0625rem;
  background-color: $darkest;
}

.search {
  @media (max-width: $w992) {
    display: none;
  }
}

.menu {
  display: flex;

  @media (max-width: $w1080) {
    display: none;
  }

  &_mobile {
    display: none;

    @media (max-width: $w1080) {
      display: flex;
    }
  }
}

.cartModal {
  padding: 0 !important;
}

.empty {
  flex-grow: 1;
  margin-bottom: 150px;
}

.controlsWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
}

.julirea {
  position: relative;
  top: 0.3125rem;
  margin-right: 1rem;

  @media (max-width: $w1080) {
    display: none;
  }

  &_black {
    svg path {
      fill: $white;
    }
  }

  &__topTicker,
  &__bottomTicker {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    font-size: 0.6875rem;
    line-height: 1;
    color: $tickerColor;
  }

  &__topTicker {
    top: -1.375rem;
  }

  &__bottomTicker {
    bottom: -0.5rem;
  }
}

.linksWrapper {
  transform: translateY(-0.1875rem);

  @media (max-width: $w1080) {
    display: none;
  }
}

.kidsLink,
.julireaLink {
  font-size: 1.25rem;

  @media (max-width: 81.25rem) {
    font-size: 1.125rem;
  }
}

.kidsLink {
  background: linear-gradient(
    86deg,
    #7852bd -40.52%,
    #e94f9b 30.69%,
    #f0a349 105.93%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 1.5rem;

  @media (max-width: 81.25rem) {
    margin-right: 1rem;
  }
}

.julireaLink {
  color: $primary;
  font-weight: 400;

  &--black {
    color: $white;
  }
}
