@import 'styles/vars';
@import 'styles/scss/mixins/fluid-font-size.scss';

.wrapper {
  margin-bottom: 2.5rem;
  padding: 2.15rem 2.15rem 0;
}

.freeWrapper {
  margin-bottom: 1rem;
  padding-top: 0rem;
}

.bar {
  background-color: rgba(0, 0, 0, 0.1);
  height: 4px;
}

.progress {
  position: relative;
  height: inherit;
  background-color: $brown;
  transition: width 0.75s ease-out;
  will-change: width;
}

.mark {
  position: absolute;
  right: 0;
  top: -10px;
  bottom: -10px;
  margin: auto;
  width: 10px;
  height: 10px;
  transform: translate3d(50%, 0, 0);

  background-color: $brown;
  border: 1px solid $white;
  border-radius: 50%;

  svg {
    position: absolute;
    left: -1000px;
    right: -1000px;
    margin: auto;
    bottom: calc(100% + 7px);
  }
}

.bottom {
  margin: 0.6666em 0 0;
  @include fluid-font-size(1rem, 1.0625rem, 1.125rem);
}

.price {
  color: $secondary;
}

.free {
  color: $white;
  background: $brown;
  text-align: center;
  padding: 0.5rem;
  margin: 0;
  font-size: $f18;

  @media (max-width: $w1200) {
    font-size: $f16;
  }
}
