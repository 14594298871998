@use 'sass:math';

// colors
$primary: #000;
$secondary: rgba(0, 0, 0, 0.5);
$blackLight: #575757;
$red: #eb5757;
$green: #27ae60;
$brown: #af704d;
$brown40t: rgba(175, 111, 77, 0.74);
$brown-d2: #d2886d;
$brownLight: #e1af74;
$brownLight2: #dbac7e;
$brownDark: #9b4f24;
$brownLighter: #d7b7a6;
$brownLightest: #f5e9e0;
$headerBorder: #e5e5e5;
$light: #f8f5f0;
$gray: rgba(0, 0, 0, 0.1);
$grey-8c-30t: rgba(140, 140, 140, 0.3);
$lightGray: #c4c4c4;
$gray2: #e6e2e0;
$black20t: rgba(0, 0, 0, 0.2);
$black25t: rgba(0, 0, 0, 0.25);
$black05t: rgba(0, 0, 0, 0.05);
$dark: #2d2d2d;
$dark50t: rgba($dark, 0.5);
$darkest: #232323;
$white05t: rgba(255, 255, 255, 0.05);
$white50t: rgba(255, 255, 255, 0.5);
$white10t: rgba(255, 255, 255, 0.1);
$white20t: rgba(255, 255, 255, 0.2);
$white30t: rgba(255, 255, 255, 0.3);
$white: #ffffff;
$videoOverlay: rgba(91, 83, 83, 0.71);
$blue: #007eed;
$blue50t: rgba(0, 126, 237, 0.5);
$redSecondary: #eb3223;
$tickerColor: #cecdcc;
$tickerColorMobile: rgba(206, 205, 204, 0.2);
$transparent: rgba(255, 255, 255, 0);

// sizes
$head-size: 6.25rem;
$head-size-mobile: 4.375rem;
$f10: 0.625rem;
$f11: 0.6875rem;
$f12: 0.75rem;
$f14: 0.875rem;
$f16: 1rem;
$f18: 1.125rem;
$f20: 1.25rem;
$f22: 1.375rem;
$f23: 1.4375rem;
$f24: 1.5rem;
$f26: 1.625rem;
$f28: 1.75rem;
$f30: 1.875rem;
$f31: 1.9375rem;
$f34: 2.125rem;
$f36: 2.25rem;
$f40: 2.5rem;
$f43: 2.6875rem;
$f46: 2.875rem;
$f50: 3.125rem;
$f56: 3.5rem;
$f60: 3.75rem;
$f64: 4rem;
$f66: 4.125rem;
$f70: 4.375rem;
$f80: 5rem;
$f86: 5.375rem;
$f100: 6.25rem;
$f110: 6.875rem;
$f130: 8.125rem;

// padd
$padd: 5.75rem;
$padd-medium: 2.5rem;
$padd-small: 1.25rem;

//marg
$marg16: 1rem;
$marg24: 1.5rem;
$marg80: 5rem;
$marg140: 8.75rem;

//breakpoints
$w1400: 87.5rem;
$w1366: 85.375rem;
$w1200: 75rem;
$w1100: 68.75rem;
$w1080: 67.5rem;
$w992: 62rem;
$w768: 48rem;
$tabletMaxWidth: $w768 - 0.0625rem;
$w576: 36rem;

// transitions
$base-speed-in: 150ms;
$base-speed-out: 200ms;

$ease-in: $base-speed-in ease-in;
$ease-out: $base-speed-out ease-out;
$ease-in-slow: $base-speed-in * 2.5 ease-in;
$ease-out-slow: $base-speed-out * 2.5 ease-out;
$ease-in-fast: math.div($base-speed-out, 2) ease-in;
$ease-out-fast: math.div($base-speed-out, 2) ease-out;

//fonts
$apoc: Apoc LC, 'Apoc LC', Arial, sans-serif;
