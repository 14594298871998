@import 'styles/vars';
.wrapper {
  padding: 0 2.15rem 1.75rem;
}

.button {
  width: 100%;
  height: 3.75rem;

  &:hover {
    color: $white;
  }
}

.table {
  margin: 1.75rem 0;
  width: 100%;
  font-size: $f28;
  border-collapse: collapse;

  td {
    padding: 2px 0;
  }

  td:last-child {
    text-align: right;
  }
}

@keyframes tableLoading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}

.loading {
  will-change: opacity;
  animation: tableLoading 1.5s ease-in-out 0s infinite;
}
