@import '../../../styles/vars.scss';

.wrapper {
  background: $white;
  box-shadow: 0px -0.625rem 2.75rem rgba(175, 112, 77, 0.15);
  color: $secondary;
  padding: 1.875rem 0;
  position: fixed;
  bottom: -50vh;
  left: 0;
  right: 0;
  z-index: 3;
  transition: bottom $ease-in-slow;

  &.active {
    bottom: 0;
  }
}

.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4.375rem 0 1.875rem;

  @media (max-width: $w992) {
    flex-direction: column;
    padding: 0;
    text-align: center;
  }
}

.text {
  font-size: $f14;
  line-height: 1.5;
  letter-spacing: 0.01em;
  max-width: 66.25rem;
  margin: 0;
}

.button {
  background: transparent;
  border: none;
  text-transform: lowercase;
  color: $brown;
  font-size: $f20;
  line-height: 1;
  letter-spacing: 0.01em;
  padding: 0;
  cursor: pointer;
  transition: color $ease-in;
  margin-left: $marg24;

  @media (max-width: $w992) {
    margin-left: 0;
    margin-top: $marg24;
  }

  &:hover {
    color: $brownDark;
  }

  &:active,
  &:focus {
    outline: none;
  }
}
